/**
 * This component will add a copy to clipboard functionality
 *
 * @module views/components/CopyToClipboard
 * @memberof -Common
 */
import './CopyToClipboard.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';

import { copyTextToClipboard } from '@ulta/utils/Formatters/Formatters';

const DEFAULT_BUTTON_PROPS = {
  size: 'tiny',
  variant: 'tertiary'
}

/**
 * copyToClipAction onClick handler function for the copy button
 *
 * @param {String} copyText
 * @param {Function} setIsCopied
 */
export const copyText = ( copyText, setIsCopied ) => {
  copyTextToClipboard( copyText )
    .then( () => {
      setIsCopied( true );
      setTimeout( () => {
        setIsCopied( false );
      }, 5000 );
    } );
};

/**
  * Represents a CopyToClipboard component
  *
  * @method
  * @param {CopyToClipboardProps} props - React properties passed from composition
  * @returns CopyToClipboard
  */
export const CopyToClipboard = function( props ){
  const {
    couponCode,
    couponCodeLabel,
    copyIcon,
    copyToClipAction,
    copyClipAccessibilityLabel,
    copiedClipAccessibilityLabel,
    copiedToClipLabel,
    withCodeLabel = true,
    withCopyIcon = true,
    buttonProps = DEFAULT_BUTTON_PROPS,
    initialButtonVariant
  } = props;
  const [isCopied, setIsCopied] = useState( false );
  const navigatorObj = global.navigator?.clipboard;
  // TODO: need to update this to use buttonProps as cleanup https://ulta.atlassian.net/browse/DISBOLT-5316
  const buttonVariant = initialButtonVariant || buttonProps.variant;

  return (
    <div className='CopyToClipboard'>
      { withCodeLabel && couponCodeLabel && couponCode &&
      <span className='CopyToClipboard__codeLabel'>
        <Text
          htmlTag='span'
          textStyle='body-1'
        >
          { couponCodeLabel }
        </Text>
        <Text
          htmlTag='span'
          textStyle='body-1'
        >
          { couponCode }
        </Text>
      </span>
      }
      { copyToClipAction?.label && navigatorObj &&
      <span className='CopyToClipboard__copyToClipAction'
        title={ isCopied ? copiedClipAccessibilityLabel : copyClipAccessibilityLabel }
        aria-live='polite'
        role='status'
      >
        { isCopied ? (
          <Button
            iconImage='Check'
            iconSize={ 's' }
            label={ copiedToClipLabel }
            size={ buttonProps.size }
            variant={ buttonVariant }
          />
        ) : (
          <Button
            action={ copyToClipAction }
            iconImage={ withCopyIcon ? 'copy' : undefined }
            iconSize={ 's' }
            label={ copyToClipAction.label }
            onClick={ () => copyText( couponCode, setIsCopied ) }
            size={ buttonProps.size }
            variant={ buttonVariant }
          />
        ) }
      </span>
      }
    </div>
  );
};
/**
  * Property type definitions
  * @typedef CopyToClipboardProps
  * @type {object}
  * @property {string} buttonProps - Sets the button properties for the coupon code button, like size and style
  * @property {string} copyToClipAction - Sets the action for copy to clipboard
  * @property {object} copyToClipAction - Sets the action for copy to clipboard
  * @property {string} copiedToClipLabel - Sets the label of the copied
  * @property {string} copyIcon - Sets the icon for copy
  * @property {string} copyClipAccessibilityLabel - Sets the label for copy clip
  * @property {string} copiedClipAccessibilityLabel - Sets the accessibility label for copied
  * @property {string} couponCodeLabel - Sets the label for coupon code
  * @property {string} couponCode - Sets the code value for coupon code
  * @property {string} withCodeLabel - True if the code label to be copied should be displayed; otherwise, false
  * @property {string} withCopyIcon - True if the copy icon label should be displayed; otherwise, false
  * @property {string} initialButtonVariant - Sets the button variant
  */
export const propTypes =  {
  buttonProps: PropTypes.shape( {
    size: PropTypes.string,
    variant: PropTypes.string
  } ),
  copiedToClipLabel: PropTypes.string,
  copyToClipAction: PropTypes.object,
  copyClipAccessibilityLabel: PropTypes.string,
  copiedClipAccessibilityLabel: PropTypes.string,
  couponCodeLabel: PropTypes.string,
  couponCode: PropTypes.string,
  copyIcon: PropTypes.string,
  withCodeLabel: PropTypes.bool,
  withCopyIcon: PropTypes.bool,
  initialButtonVariant: PropTypes.string
};


CopyToClipboard.propTypes = propTypes;

export default CopyToClipboard;
