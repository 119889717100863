import React from 'react';

import Button from '@ulta/core/components/Button/Button';

/**
 * Function for button action style
 * @function getButtonHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getButtonHtml = ( data ) => {
  const {
    action,
    actionStyle,
    derivedAriaLabel,
    fullyClickableParent,
    isKnockoutLink
  } = data;

  const isButtonStyle = ( actionStyle === 'primary' || actionStyle === 'secondary' || actionStyle === 'tertiary' );
  const derivedButtonSize = isButtonStyle ? 'compact' : 'default';
  const updateTabIndex = fullyClickableParent ? -1 : undefined;

  return (
    <>
      <Button
        variant={ actionStyle }
        fullyClickableParent={ fullyClickableParent }
        isKnockoutLink={ isKnockoutLink }
        ariaLabel={ derivedAriaLabel }
        tabIndex={ updateTabIndex }
        size={ derivedButtonSize }
        action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with the web-site Button. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
        { ...action }
      />
    </>
  );
};