/**
 * Common ProductCard component used by other modules as a reusable component which returns the ui for a product card.
 *
 * @module views/Molecules/ProductCard
 * @memberof -Common
 */
import './ProductCard.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';

import { useProductRailHorizontalContext } from '@ulta/providers/ProductRailHorizontalProvider/ProductRailHorizontalProvider';

import ProductPricing from '@ulta/modules/ProductPricing/ProductPricing';

import RemoveItem from '../RemoveItem/RemoveItem';
import ReviewStarsCard from '../ReviewStarsCard/ReviewStarsCard';
import * as utils from './ProductCard';


/**
 * Represents a ProductCard component
 *
 * @method
 * @param {ProductCardProps} props - React properties passed from composition
 * @returns ProductCard
 */
export const ProductCard = function( props ){

  let {
    ariaLabelBookmark,
    type,
    size,
    root,
    rootMargin,
    onDataCaptureEvent,
    onIntersectionEvent,
    skuId,
    productId,
    componentKey,
    removeFavoriteAction,
    removeFavoriteDialog,
    invokeMutation,
    action,
    badge,
    brandName,
    productName,
    image,
    reviewCount,
    rating,
    reviewAccessibilityLabel,
    listPrice,
    salePrice,
    kitPrice,
    priceLabel,
    messages,
    promoText,
    additionalOffersText,
    displayBookmark,
    viewOptionAction,
    variantLabel,
    addToBagAction,
    carouselIndex,
    salePriceAccessibilityLabel,
    listPriceAccessibilityLabel
  } = props;

  let imgDimension = setImageParams( type, size ) * ( global.devicePixelRatio || 1 );

  const productRef = useRef();
  const hasFiredDCEvent = useRef( false );
  const [isButtonClicked, setIsButtonClicked] = useState( false );
  const [loader, showLoader, hideLoader] = useLoader( { loading: false, isPageLoader: true, color: 'orange-400' } );
  const bookmarkLabel = ariaLabelBookmark || removeFavoriteAction?.label;
  const { isOverlay } = useOverlay();
  // We are setting an empty object as default value for visibleSlidesIndexes because
  // the context (visibleSlidesIndexes) is only available for product cards inside a swiper carousel
  const { visibleSlidesIndexes } = useProductRailHorizontalContext() || {};

  // Handles last scrolled threshold
  const { intersectionRatio, hasIntersected } = useIntersectionObserver( productRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: [0, 0.5, 1.0]
  } );

  const atbAction = {
    ...addToBagAction,
    variables :{
      moduleParams: {
        skuId,
        productId
      }
    }
  };

  const isAddOverlay = isOverlay( atbAction.navigationType );
  if( !isAddOverlay ){
    atbAction.variables.moduleParams.quantity = 1;
  }

  useEffect( () => {
    if( intersectionRatio === 1 ){
      utils.onIntersection( { skuId }, { onIntersectionEvent } );
    }
    else if( !hasFiredDCEvent.current && hasIntersected === true && intersectionRatio >= 0.5 ){
      hasFiredDCEvent.current = true;
      utils.onDataCapture( { skuId }, { onDataCaptureEvent } );
    }
  }, [hasIntersected, intersectionRatio, onDataCaptureEvent, onIntersectionEvent] );

  // Compose action.after function for ATB action
  const handleActionAfter = useCallback(
    utils.composeActionAfter( { hideLoader } ), [hideLoader]
  );

  // Compose onClick for Product Card ATB
  // -> handles invoking the action from DXL
  const handleOnClick = useCallback(
    utils.composeOnClick(
      { productId, skuId, isAddOverlay, atbAction },
      { invokeMutation, showLoader, handleActionAfter }
    ),
    [invokeMutation, productId, skuId, isAddOverlay, atbAction, showLoader, handleActionAfter]
  );

  // For ada tabbing through carousel, we need to set the tabindex to -1 if the product is not visible in the carousel
  const isVisibleInCarousel = visibleSlidesIndexes?.length && !!visibleSlidesIndexes?.includes( carouselIndex );
  const isFocusable = isVisibleInCarousel !== false;

  return (
    <div className='ProductCard'
      ref={ productRef }
      data-visible={ intersectionRatio === 1 }
      data-item-id={ skuId }
      role='group'
      aria-label='product'
    >
      { loader }
      <Link_Huge
        action={ action }
        tabIndex={ isFocusable ? 0 : -1 }
      >
        <div
          className={
            classNames( 'ProductCard__image', {
              [`ProductCard__image__${type}__${size}`]: size && type
            } )
          }
        >
          <Image
            alt={ `${brandName} ${productName}` }
            src={ image }
            ariaHidden={ true }
            metaData={ {
              width: imgDimension,
              height: imgDimension,
              imageTemplate: 'ProductCardNeutralBG'
            } }
            shouldUseIntersectionObserver={ false }
          />
          { badge &&
            <div className='ProductCard__badge'>
              <Text
                textStyle='body-3'
              >
                { badge }
              </Text>
            </div>
          }
          { variantLabel &&
            <span className='ProductCard__variant'>
              <Text
                textStyle='body-3'
                color='neutral-600'
              >
                { variantLabel }
              </Text>
            </span>
          }
        </div>
        <div className='ProductCard__content'>
          <h3 className='ProductCard__heading'>
            { brandName &&
              <span className='ProductCard__brand'>
                <Text
                  textStyle='body-2'
                  color='neutral-600'
                  htmlTag='span'
                >
                  { brandName }
                </Text>
              </span>
            }
            { productName &&
              <span className='ProductCard__product'>
                <Text
                  textStyle='body-2'
                  htmlTag='span'
                >
                  { productName }
                </Text>
              </span>
            }
          </h3>
          { !!reviewCount && !!rating &&
            <div className='ProductCard__rating'>
              <ReviewStarsCard
                reviews={ reviewCount }
                rating={ rating }
                reviewAccessibilityLabel={ reviewAccessibilityLabel }
                componentKey={ componentKey }
              />
            </div>
          }
          { listPrice &&
            <div className='ProductCard__price'>
              <ProductPricing
                listPrice={ listPrice }
                salePrice={ salePrice }
                kitPrice={ kitPrice }
                priceLabel={ priceLabel }
                salePriceAccessibilityLabel={ salePriceAccessibilityLabel }
                listPriceAccessibilityLabel={ listPriceAccessibilityLabel }
                compact
              />
            </div>
          }
          { promoText &&
            <div className='ProductCard__offers'>
              <div>
                <Text
                  textStyle='body-3'
                  color='magenta-500'
                >
                  { promoText }
                </Text>
              </div>
              { additionalOffersText &&
                <div>
                  <Text htmlTag='span'
                    textStyle='body-3'
                    color='magenta-500'
                  >
                    { additionalOffersText }
                  </Text>
                </div>
              }
            </div>
          }
        </div>
      </Link_Huge>
      { displayBookmark && removeFavoriteDialog &&
        <div className='ProductCard__favorite'>
          <Button
            icon
            tertiary
            tiny
            iconSize={ size === 'xLarge' ? 'l' : 'm' }
            iconImage={ removeFavoriteAction ? 'HeartFilled' : 'HeartEmpty' }
            ariaLabel={ bookmarkLabel }
            ariaHiddenIcon={ true }
            onClick={ ( e ) => {
              setIsButtonClicked( true );
              e.stopPropagation();
              e.preventDefault();
            } }
            action={ {
              ...removeFavoriteAction,
              navigationType: 'modal'
            } }
            overlayContent={
              <RemoveItem
                headerText={ removeFavoriteDialog.title }
                confirmLabel={ removeFavoriteDialog.confirmLabel }
                confirmMessage={ removeFavoriteDialog.message }
                cancelLabel={ removeFavoriteDialog.cancelLabel }
                action={ removeFavoriteAction }
                invokeMutation={ invokeMutation }
                moduleParams={ { 'skuId': skuId } }
                messages={ utils.RemoveFavoriteMessage( isButtonClicked, messages ) }
                renderComponent={ {
                  image: {
                    'imageUrl': image,
                    'width': 70,
                    'metaData': { width: 70 }
                  },
                  productName: productName,
                  salePrice: listPrice || salePrice,
                  moduleName: 'MiniProductCard'
                } }
              /> }

          ></Button>
        </div>
      }
      { !viewOptionAction && addToBagAction && (
        <div className='ProductCard__addToBag'>
          <Button
            tabIndex={ isFocusable ? 0 : -1 }
            ariaHiddenIcon={ true }
            compact={ true }
            onClick={ handleOnClick }
            action={ atbAction }
            label={ atbAction.label }
          ></Button>
        </div>
      ) }
      { viewOptionAction && (
        <div className='ProductCard__options'>
          <Button
            tabIndex={ isFocusable ? 0 : -1 }
            ariaLabel={ `${brandName} ${productName} ${viewOptionAction.label}` }
            ariaHiddenIcon={ true }
            compact={ true }
            secondary={ true }
            onClick={ ( e ) => {
              e.stopPropagation();
              e.preventDefault();
              handleRedirect( { url: viewOptionAction.url } );
            } }
            action={ viewOptionAction }
            label={ viewOptionAction.label }
          ></Button>
        </div>
      ) }
    </div>
  );
};

/**
   * Method to handle action.after callback for atbAction
   * @param {object} methods methods
   * @param {action} methods.closeOverlay utility from useOverlay to close overlay
   * @param {action} methods.hideLoader utility from useLoader to hide loader
   * @param {action} inputData args
   */
export const composeActionAfter = ( methods ) => () => {
  const { hideLoader } = methods || {};

  if( !hideLoader ){
    return;
  }

  hideLoader();
};

/**
   * Method to handle invoking add to bag from product card actions
   * @param {object} methods methods
   * @param {action} methods.invokeMutation handler from layerhost to invoke DXL mutation
   * @param {string} action DXL action
   */
export const composeOnClick = ( data, methods ) => ( ) => {
  const { skuId, productId, isAddOverlay, atbAction } = data || {};
  const { invokeMutation, showLoader, handleActionAfter } = methods || {};
  const { graphql, customHeaders, params, variables } = atbAction || {};

  if( !invokeMutation || !skuId || !productId || !atbAction || !showLoader || isAddOverlay ){
    return;
  }

  showLoader();

  const mutation = {
    after: handleActionAfter,
    graphql,
    customHeaders,
    params,
    variables
  };

  invokeMutation( mutation );
};

/**
 * Set the remove favorite message
 * @param {boolean} isButtonClicked
 * @param {array} messages
 */
export const RemoveFavoriteMessage = async( isButtonClicked, messages ) => {
  return await ( isButtonClicked && hasItems( messages ) ) ? [] : messages;
};

/**
 * Method to handle on onIntersection event
 * @param {object} data
 * @param {function} onIntersectionEvent
 */
export const onIntersection = ( data, methods ) => {
  const { skuId } = data || {};
  const { onIntersectionEvent } = methods || {};
  if( skuId && onIntersectionEvent ){
    onIntersectionEvent( skuId );
  }
};

/**
 * Method to handle onDataCapture event
 * @param {object} data
 * @param {function} onDataCaptureEvent
 */
export const onDataCapture = ( data, methods ) => {
  const { skuId } = data || {};
  const { onDataCaptureEvent } = methods || {};
  if( skuId && onDataCaptureEvent ){
    onDataCaptureEvent( skuId );
  }
};



/**
 * Function to determine if the Product Card is both 'compact' and 'small'.
 *
 * @method isCompactAndSmall
 * @param {string} type - This is the type of Product Card, i.e full/regular/compact.
 * @param {string} size - This is the size of the Product Card, i.e small/xLarge.
 * @returns {boolean} Returns true if the Product Card is both 'compact' and 'small'.
 **/

export const isCompactAndSmall = ( type, size ) => {
  return type === 'compact' && size === 'small';
};

/**
 * Function to return image params as per type and size.
 *
 * @method setImageParams
 * @param {string} type - This is the type of Product Card, i.e full/regular/compact.
 * @param {string} size - This is the size of the Product Card, i.e small/xLarge.
 * @returns {string} Returns string consisting width and height as per type and size
 **/

export const setImageParams = ( type, size ) => {
  const cardTypeSize = `${type}_${size}`;

  switch ( cardTypeSize.toLocaleLowerCase() ){
    case 'compact_small':
      return 120;

    case 'full_small':
    case 'regular_xlarge':
    case 'compact_xlarge':
      return 240;

    case 'full_xlarge':
      return 360;

    case 'regular_small':
      return 160;

    default:
      return 240; // since type full and nSize small are the default props values
  }
};

/**
 * Property type definitions
 * @typedef ProductCardProps
 * @type {object}
 * @property {string} type - This is the type of Product card going forward PC (currently one of 'full', 'regular', 'compact')
 * @property {string} size - This is the type of size of PC (currently one of 'small', 'xLarge')
 * @property {object} action - This the the action object which have navigational url on click of PC
 * @property {string} additionalOffersText - This is the additionalOffersText for the product
 * @property {string} bookmarkAccessibility - This is the bookmarkAccessibility for the product
 * @property {boolean} bookmarked - This will set bookmarked for the product
 * @property {array} availability - This is the list of availability tag for the product
 * @property {string} badge - This is the badge i.e new Arrival, coming soon etc
 * @property {string} image - This is the product Image
 * @property {string} altImage - This is the alternate Image of product
 * @property {string} variantLabel - This is variant label of Product
 * @property {string} brandName - This is name of brand
 * @property {string} productName - This is name of product
 * @property {string} skuId - This is the sku
 * @property {string} productId - This is the product ID
 * @property {string} kitPrice - This is kitPrice of product
 * @property {string} listPrice - This is listPrice of product
 * @property {string} salePrice - This is the salePrice of product
 * @property {string} priceLabel - This is the priceLabel of product
 * @property {number} rating - This is the rating of the product
 * @property {number} reviewCount - This is the reviewCount for the product
 * @property {string} promoText - This is the promo text for the product
 * @property {string} ariaLabelBookmark - This is the aria label for bookmark
 * @property {boolean} displayBookmark - This sets the flag to display bookmark
 * @property {string} maxQuantity - This sets the max quantity of product to be added to bag
 * @property {object} addToBagAction - Sets label and graphql for addtobag button
 * @property {object} viewOptionAction - Sets label and graphql for options button
 * @property {string} reviewAccessibilityLabel - This is the reviewAccessibilityLabel for the product
 * @property {function} onDataCaptureEvent - This is the onDataCaptureEvent for the product
 * @property {function} onIntersectionEvent - This is the onIntersectionEvent for the product
 * @property {boolean} isVisibleInCarousel - This is a flag to check if the ada tabindex is visible in carousel
 * @property {number} componentKey - Sets the component key
 * @property {object} removeFavoriteAction - Sets the remove Favorite Action
 * @property {object} removeFavoriteDialog - Sets the remove Favorite Dialog
 * @property {function} invokeMutation - Sets the invoke Mutation to send child component
 * @property {number} contentId - Sets the content id
 * @property {array} messages - Sets the error message
 * @property {string} salePriceAccessibilityLabel - Sets the sale price accessibility label
 * @property {string} listPriceAccessibilityLabel - Sets the list price accessibility label
 *
 */
export const propTypes = {
  /** This is the type of Product card going forward PC (currently one of 'full', 'regular', 'compact') */
  type: PropTypes.oneOf( [
    'full',
    'regular',
    'compact'
  ] ),
  /** This is the type of size of PC (currently one of 'small', 'xLarge') */
  size: PropTypes.oneOf( [
    'small',
    'xLarge'
  ] ),
  /** This is the navigational url on click of PC */
  action: PropTypes.shape( {
    url: PropTypes.string
  } ),
  /** This is the additionalOffersText for the product */
  additionalOffersText: PropTypes.string,
  /** This is the bookmarkAccessibility for the product */
  bookmarkAccessibility: PropTypes.string,
  /** This will set bookmarked for the product */
  bookmarked: PropTypes.bool,
  /** This is the list of availability tag for the product */
  availability: PropTypes.array,
  /** This is the badge i.e new Arrival, coming soon etc */
  badge: PropTypes.string,
  /** This is the product Image */
  image: PropTypes.string,
  /** This is the alternate Image of product */
  altImage: PropTypes.string,
  /** This is  variant label for prduct i.e colors/sizes */
  variantLabel: PropTypes.string,
  /** This is name of brand */
  brandName: PropTypes.string,
  /** This is name of product */
  productName: PropTypes.string,
  /** This is sku of product */
  skuId: PropTypes.string,
  /** This is product ID of product */
  productId: PropTypes.string,
  /** This is kitPrice of product */
  kitPrice: PropTypes.string,
  /** This is listPrice of product */
  listPrice: PropTypes.string,
  /** This is salePrice of product */
  salePrice: PropTypes.string,
  /** This is price label of product */
  priceLabel: PropTypes.string,
  /** This is the rating of the product */
  rating: PropTypes.number,
  /** This is the reviewCount for the product */
  reviewCount: PropTypes.number,
  /** This is the promo text for the product */
  promoText: PropTypes.string,
  /** This is the aria label for bookmark */
  ariaLabelBookmark: PropTypes.string,
  /** This sets the flag to display bookmark */
  displayBookmark: PropTypes.bool,
  /** This sets additional dataCaptureAttributes this is not used in display */
  dataCapureAttributes: PropTypes.object,
  /** This sets the max quantity of product to be added to bag */
  maxQuantity: PropTypes.number,
  /** This sets the label and graphql for add to button */
  addToBagAction: PropTypes.object,
  /** This sets the label and graphql for options button */
  viewOptionAction: PropTypes.object,
  /**  This is the reviewAccessibilityLabel for the product */
  reviewAccessibilityLabel: PropTypes.string,
  /**  This is the onDataCaptureEvent for the product */
  onDataCaptureEvent: PropTypes.func,
  /**  This is the onIntersectionEvent for the product */
  onIntersectionEvent: PropTypes.func,
  /** Sets the component key */
  componentKey: PropTypes.number,
  /** Sets the remove Favorite Action */
  removeFavoriteAction: PropTypes.object,
  /** Sets the remove Favorite Dialog */
  removeFavoriteDialog: PropTypes.object,
  /** Sets the remove invoke Mutation */
  invokeMutation: PropTypes.func,
  /** Sets the content id */
  contentId: PropTypes.oneOfType( [PropTypes.number, PropTypes.string] ),
  /** Sets the messages */
  messages: PropTypes.array,
  /** Sets the carousel index from swiper */
  carouselIndex: PropTypes.number,
  /** Sets the sale price accessibility label */
  salePriceAccessibilityLabel: PropTypes.string,
  /** Sets the list price accessibility label */
  listPriceAccessibilityLabel: PropTypes.string
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} type='full' - The product card type, default is full.
 * @property {string} size='small' - The product card size, default is small.
 * @property {boolean} displayBookmark=false - Flag to specify if the display bookmark is available, default is false
 */
export const defaultProps = {
  type: 'full',
  size: 'small',
  displayBookmark: false,
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

ProductCard.propTypes = propTypes;
ProductCard.defaultProps = defaultProps;

export default ProductCard;