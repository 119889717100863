/**
 * ActionGroups Component
 * @module views/modules/ActionGroups
 */

import React from 'react';

import PropTypes from 'prop-types';

import ActionGroupDefault from '@ulta/core/components/ActionGroupDefault/ActionGroupDefault';
import ActionGroupMulti from '@ulta/core/components/ActionGroupMulti/ActionGroupMulti';

/**
 * Represents a ActionGroups component
 *
 * @method
 * @param { ActionGroupsProps } props
 * @returns ActionGroups
 */

export const ActionGroups = function( props ){
  const {
    // ActionGroup related props
    variant,
    // Array of actions
    actions,
    // Styles set in Amplience
    style,
    types,
    // Generated styles based coming from parent components
    alignment,
    fullyClickableParent,
    isKnockoutLink
  } = props;

  // Checks the variant to determine which ActionGroup to display
  const isActionGroupDefault = variant === 'default';
  const isActionGroupMulti = variant === 'multi';

  return (
    <>
      { isActionGroupDefault &&
        <ActionGroupDefault
          action1={ actions[0] }
          action2={ actions[1] }
          fullyClickableParent={ fullyClickableParent }
          isKnockoutLink={ isKnockoutLink }
          style={ style }
          type={ types }
        />
      }
      { isActionGroupMulti && !fullyClickableParent &&
        <ActionGroupMulti
          actions={ actions }
          alignment={ alignment }
          isKnockoutLink={ isKnockoutLink }
          style={ style }
          types={ types }
        />
      }
      { fullyClickableParent &&
        <ActionGroupDefault
          action1={ actions[0] }
          fullyClickableParent={ fullyClickableParent }
          style={ style }
          type={ types }
        />
      }
    </>
  );
};

/**
 * Property type definitions
 * @typedef ActionGroups
 * @type {object}
 * @property {array} actions - allows passing an array of actions
 * @property {string} alignment - sets the actions to centered
 * @property {string} buttonStyle - sets the button style for ActionGroups
 * @property {boolean} isKnockoutLink - sets the link text to white
 * @property {string} linkStyle - sets the link style for ActionGroups
 * @property {string} style - sets the style of the ActionGroups
 * @property {string} types - sets the types of the ActionGroups
 * @property {string} variant - sets the variant of the ActionGroups
 */

const propTypes = {
  /**
   * Allows passing an array of actions
   */
  actions: PropTypes.array,
  /**
   * Sets the actions to centered
   */
  alignment: PropTypes.string,
  /**
   * Sets the link text to white
   */
  isKnockoutLink: PropTypes.bool,
  /**
   * sets the style of the ActionGroups
   */
  style: PropTypes.string,
  /**
   * type component above the eyebrow
   */
  types: PropTypes.string,
  /**
   * sets the variant of the ActionGroups
   */
  variant: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} alignment - alignment: 'left' - sets the default for the alignment
 */
export const defaultProps =  {
  alignment: 'left'
};

ActionGroups.propTypes = propTypes;
ActionGroups.defaultProps = defaultProps;

export default ActionGroups;