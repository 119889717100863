import React from 'react';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

/**
 * Function for link action style
 * @function getLinkHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getLinkHtml = ( data ) => {
  const {
    action,
    actionStyle,
    derivedAriaLabel,
    fullyClickableParent,
    iconName,
    iconPosition,
    isKnockoutLink
  } = data;

  const isButtonStyle = ( actionStyle === 'buttonPrimary' || actionStyle === 'buttonSecondary' || actionStyle === 'buttonTertiary' );
  const derivedButtonSize = isButtonStyle ? 'compact' : 'default';
  const updateTabIndex = fullyClickableParent ? -1 : undefined;

  return (
    <>
      <Link_Huge
        variant={ actionStyle }
        isKnockoutLink={ isKnockoutLink }
        fullyClickableParent={ fullyClickableParent }
        ariaLabel={ derivedAriaLabel }
        tabIndex={ updateTabIndex }
        size={ derivedButtonSize }
        children={ action?.label }
        action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with Link_Huge. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
        { ...action }
        { ...( iconName && { iconImage: iconName } ) }
        { ...( iconPosition && { iconPosition: iconPosition } ) }
      />
    </>
  );
};